<template>
  <div class="imgWrapper">
    <img src="~assets/404.png" alt="" class="img">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
  .imgWrapper
    height 100vh
    display flex
    flex-direction column
    align-items center
    background-color #f3f6fb
    padding-top 40%
    .img
      width 80%
</style>